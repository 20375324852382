import React from 'react';
import { Layout, Intro, Programs, Events, About, News, Contact, Testimonials, Partners } from '../components';

export default () => {
    if (typeof window !== `undefined` && typeof window.onGatsbyInitialClientRender === 'function') {
        setTimeout(window.onGatsbyInitialClientRender);
    }

    return (
        <Layout pageTitle={'Home'} showSideBar={true}>
            <div id="wrapper">
                <Intro />
                <Programs />
                <Events />
                <About />
                <News />
                <Testimonials />
                <Partners />
                <Contact />
            </div>
        </Layout>
    );
}
